import React from "react";
import "../styles/button.css";

import { Link } from "react-router-dom";

const Button = (props) => {
    return (
        <>
            <Link to={props.link} className="btn-style cursor-pointer">
                <span className="btn-title">
                    {props.name}</span>
            </Link>
        </>
    )
}

export default Button;