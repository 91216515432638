import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EventCard = (props) => {
    return (
        <div className="w-10/12 flex flex-col items-center h-fit p-8 gap-4 rounded-xl border border-gray-200 transition-all hover:shadow-md ease-in-out duration-300 hover:-translate-y-2">
            <div className="w-full flex flex-col items-center gap-4">
                <FontAwesomeIcon icon={props.icon} className='w-[48px] h-[48px] text-stone-900' />
                <h1 className="text-2xl md:text-3xl text-center helvetica font-semibold text-stone-900">{props.title}</h1>
                <p className="text-[0.6rem] md:text-sm text-center leading-5 md:leading-8 h-fit text-stone-800">
                    {props.content}
                </p>
            </div>
            <div className="w-full">
                <Link to={props.link} className="grid content-center bg-white border-2 border-gray-100 w-full h-12 p-3 rounded-lg text-[#695497] transition ease-in-out hover:bg-[#695497] hover:text-white duration-300">See More</Link>
            </div>
        </div>
    )
}

export default EventCard;