import React from "react";

import Button from "./Button";

const Banner = (props) => {
    return (
        <div className="bg-white border-b my-24">
            <div className="relative isolate">
                <div className="flex mx-auto max-w-5xl py-36 justify-center items-center">
                    <div className="">
                        <div className="mb-8 flex justify-center">
                            <div className="relative rounded-full px-3 py-1 w-36 text-sm leading-6 text-stone-900 bg-[#FDCF50] hover:text-stone-900 hover:bg-white hover:ring-1 hover:ring-stone-900">
                                LCTIP XXXII
                            </div>
                        </div>
                        <div className="text-center">
                            <h1 className="text-4xl md:text-5xl lg:text-6xl helvetica font-extrabold text-stone-900 px-8">
                                {props.title}
                            </h1>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <Button name="Register" link={props.link} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
