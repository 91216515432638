import React from 'react'

import Banner from '../components/Banner'
import InformationCard from '../components/InformationCard';
import SectionTitle from '../components/SectionTitle';
import TimelineCard from '../components/TimelineCard';
import SponsorsCard from '../components/SponsorsCard';

import Pics from "../assets/logo.png";
import Organizer from '../assets/organizer.png';

const SeminarPage = () => {
    return (
        <main>
            <Banner title="NATIONAL SEMINAR" link="https://docs.google.com/forms/d/e/1FAIpQLScIPEg3lPkRU80J6UdNPvde5KjKWgKRjAt1JvkrV83CFH8vgQ/viewform" />
            <div className='space-y-12'>
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Symphony of Creamy Bliss as A Trend in Food Industry" category="Seminar Theme" isWhite={true} isLeft={true} content={Pics} description={<>Seminar nasional ini diharapkan dapat memberikan wawasan dan pengetahuan bagi para akademisi dan masyarakat umum tentang pangan bersifat creamy dan produk olahannya serta beragam manfaat mengonsumsinya. Melalui seminar nasional ini, kami harap pangan bersifat creamy dapat dioptimalisasikan dalam pengolahannya agar lebih bernutrisi dan dikonsumsi dengan segudang manfaat serta keunggulannya.</>} />
                </section>

                {/* Timeline */}
                <section className="w-full bg-[#695497] py-20 gap-12">
                    <SectionTitle category="Reminder" title="Events Timeline" isWhite={false} />
                    <div className="w-9/12 flex flex-col mx-auto gap-4">
                        <TimelineCard event="Registrasi" date="15 September - 15 Oktober 2024" />
                        <TimelineCard event="National Seminar" date="2 November 2024" />
                    </div>
                </section>

                {/* Organizer */}
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="Behind the Greatest Event" isWhite={true} isLeft={true} content={Organizer} description={<>Acara ini diselenggarakan oleh Himpunan Mahasiswa Ilmu dan Teknologi Pangan (HIMITEPA) dari Departemen Ilmu dan Teknologi Pangan IPB University.<br />Sebagai pionir dalam memecahkan masalah terkait pangan yang terbagi ke dalam empat divisi, yaitu Kimia Pangan, Mikrobiologi Pangan, Biokimia Pangan, dan Rekayasa Proses Pangan.</>} />
                </section>

                {/* Sponsors */}
                <section className='w-full h-full flex flex-col items-center  gap-12'>
                    <SectionTitle category="Partner in Progress" title="Sponsorship" isWhite={true} />
                    <SponsorsCard />
                </section>
            </div>
        </main>
    )
}

export default SeminarPage;