import React from 'react';

const SectionTitle = (props) => {
    return (
        <div className={`"grid ${props.isLeft ? 'justify-items-start' : 'justify-items-center'} mb-4"`}>
            <div className="pb-4">
                <div className={`rounded-full px-3 py-1 w-fit text-sm leading-6 ${props.isWhite ? 'text-stone-900 bg-[#FDCF50]' : 'text-stone-900 bg-white'} ${props.isLeft ? 'text-left' : 'text-center'}`}>
                    {props.category}
                </div>
            </div>
            <h1 className={`text-[2rem] sm:text-4xl md:text-5xl lg:text-5xl font-semibold ${props.isWhite ? 'text-stone-900' : 'text-white'} ${props.isLeft ? 'text-left' : 'text-center'}`}>
                {props.title}
            </h1>
        </div>
    )
}

export default SectionTitle;