import React from 'react';

import Banner from '../components/Banner';
import InformationCard from '../components/InformationCard';
import SectionTitle from '../components/SectionTitle';
import SponsorsCard from '../components/SponsorsCard';

import { Accordion } from 'flowbite-react';

import Organizer from '../assets/organizer.png';

const questionCompetition = [
    {
        id: 1,
        question: 'Materi-materi apa saja yang akan dilombakan di LCTIP XXXII?',
        answer: 'Materi yang dilombakan meliputi mata pelajaran Matematika, Fisika, Kimia, Biologi SMA, dan Ilmu Pangan. Referensi dan silabus untuk peserta lomba dapat diakses melalui situs web www.lctipipb.com pada halaman competition.'
    },
    {
        id: 2,
        question: 'Bagaimana pembagian babak lomba di LCTIP XXXII?',
        answer: 'Lomba LCTIP XXXII terdiri dari empat tahap, yaitu Babak Kualifikasi (Qualification Stage), Final Kuarter (Quarter Final Stage), Babak Semifinal (Semifinal Stage), dan Babak Final (Final Stage).'
    },
    {
        id: 3,
        question: 'Darimana saja peserta lomba dapat memperoleh informasi terbaru dari LCTIP XXXII?',
        answer: 'Informasi terbaru tentang LCTIP XXXII dapat diperoleh dari Guidebook LCTIP XXXI yang bisa diunduh melalui website www.lctipipb.com pada halaman competition. Jadi, pastikan untuk mengunduh guidebooknya langsung ya!'
    },
    {
        id: 4,
        question: 'Apakah semua rangkaian acara LCTIP XXXII akan dilakukan secara offline?',
        answer: 'Yupp!, tahun ini LCTIP akan melaksanakan semua rangkaian secara offline yang berlokasi di IPB University, Dramaga, Bogor.'
    },
    {
        id: 5,
        question: 'Apa itu LCTIP dan apa saja rangkaian acara LCTIP tahun ini?',
        answer: 'Lomba Cepat Tepat Ilmu Pangan atau dikenal sebagai LCTIP merupakan lomba tahunan seputar ilmu dan teknologi pangan berskala nasional untuk siswa/i SMA Sederajat dari seluruh Indonesia. Tahun ini, LCTIP kembali mengadakan lomba pangan yang ke-XXXII. Selain lomba, LCTIP XXXII juga mengadakan Seminar Nasional berhubungan dengan dunia pangan untuk civitas akademik dan masyarakat umum, Edu & Campus Tour yang memberikan gambaran terkait Departemen ITP, dan Mix n’ Make yang dapat memperkenalkan proses pengolahan dari suatu produk pangan.'
    },
    {
        id: 6,
        question: 'Apakah peserta LCTIP terdiri dari tim? dan bagaimana mekanisme perlombaan LCTIP tahun ini?',
        answer: 'Yupp, LCTIP adalah lomba tim, jadi peserta harus membentuk tim yang terdiri dari tiga siswa dengan satu guru pendamping. Setiap babak lomba memiliki mekanisme yang berbeda, tetapi jika soal dikerjakan dalam sesi individual, skornya akan digabung menjadi skor tim. Informasi lengkap mengenai mekanisme lomba dapat dibaca di Guidebook LCTIP XXXII atau di website www.lctipipb.com pada halaman competition.'
    },
    {
        id: 7,
        question: 'Apakah peserta perlu didampingi oleh guru pembimbing yang berasal dari sekolah yang sama? Apakah 1 guru pendamping dapat mendampingi lebih dari 1 tim?',
        answer: 'Yupp, betul! Peserta perlu didampingi oleh Guru pembimbing yang merupakan guru dari sekolah yang sama dengan peserta lomba. Guru pembimbing dapat mendampingi lebih dari 1 tim dari sekolah yang sama.'
    },
    {
        id: 8,
        question: 'Apa perbedaan LCTIP dengan lomba cepat tepat lainnya?',
        answer: 'LCTIP tidak hanya memiliki mata lomba Matematika, Biologi, Kimia, Fisika tetapi juga melombakan wawasan Ilmu Pangan. Selain itu, LCTIP memiliki serangkaian acara tour kampus IPB, tour laboratorium ITP IPB, dan seminar nasional yang dapat diikuti.'
    },
    {
        id: 9,
        question: 'Apa benefit dari mengikuti LCTIP?',
        answer: 'Dengan mengikuti LCTIP, peserta berkesempatan untuk dapat mengenal program studi Teknologi Pangan IPB lebih dalam melalui serangkaian acara tour kampus IPB, tour laboratorium ITP IPB, dan seminar nasional. LCTIP merupakan perlombaan tingkat nasional, sehingga peserta bisa mengenal teman-teman dari luar daerah! Pemenang utama dari LCTIP ini akan mendapatkan Golden Ticket untuk masuk ke Departemen Ilmu dan Teknologi Pangan IPB University.'
    },
    {
        id: 10,
        question: 'Apakah semua peserta akan mendapat sertifikat?',
        answer: 'Yap, betul, semua peserta akan mendapat sertifikat. Bagi peserta qualification stage tetapi tidak lolos ke tahap selanjutnya, maka akan mendapat e-sertifikat.'
    }
]

const CampusCompetition = [
    {
        id: 1,
        question: 'Apa saja yang dipelajari di Departemen Ilmu Teknologi Pangan IPB?',
        answer: 'Secara umum Departemen Ilmu dan Teknologi Pangan memiliki 4 divisi utama, yaitu Kimia Pangan, Biokimia Pangan, Mikrobiologi Pangan, dan Rekayasa Proses Pangan. Sehingga pembelajaran pada Departemen Ilmu Teknologi Pangan IPB adalah mata kuliah divisi tersebut dan turunannya.'
    },
    {
        id: 2,
        question: 'Secara umum, berapa tahun yang diperlukan Mahasiswa/i untuk lulus S1 Departemen Ilmu dan Teknologi Pangan?',
        answer: 'Secara umum, berapa tahun yang diperlukan Mahasiswa/i untuk lulus S1 Departemen Ilmu dan Teknologi Pangan?'
    },
    {
        id: 3,
        question: 'Bagaimana kualitas pengajar (dosen) di Departemen Ilmu Teknologi Pangan IPB?',
        answer: 'Pengajar-pengajar di Departemen Ilmu dan Teknologi Pangan bergelar Doktor (lulusan S3), professor atau guru besar, dan beberapa bergelar S2. Sehingga kualitas mereka dalam mengajar tidak perlu diragukan lagi, dosen-dosen di Ilmu dan Teknologi Pangan juga pastinya sudah mendapat pelatihan bagaimana tata cara mengajar yang baik dan benar'
    },
    {
        id: 4,
        question: 'Bagaimana prospek kerja mahasiswa lulusan teknologi pangan?',
        answer: 'Yupp!, tahun ini LCTIP akan melaksanakan semua rangkaian secara offline yang berlokasi di IPB University, Dramaga, Bogor.'
    },
    {
        id: 5,
        question: 'Bagaimana peluang untuk melakukan exchange maupun melanjutkan kuliah ke luar negeri bagi mahasiswa ITP?',
        answer: 'Mahasiswa ITP memiliki peluang besar dalam melakukan exchange maupun melanjutkan kuliah ke luar negeri. Salah satunya melalui program ASEAN International Mobility for Students (AIMS), Departemen ITP memiliki kerja sama yang kuat dengan Universitas Mae Fah Luang (Thailand), Universitas Kasetsart (Thailand), Universitas Putra Malaysia (Malaysia), dan Universitas Pangeran Songkla (Thailand), Universitas Ibaraki (Jepang), Universitas Pertanian dan Teknologi Tokyo (Jepang), KMITL (Thailand), Universitas Sofia (Jepang), Universitas Tsukuba (Jepang).'
    },
    {
        id: 6,
        question: 'Bagaimana akreditasi yang dimiliki Program Studi Teknologi Pangan IPB?',
        answer: 'Program Studi Teknologi Pangan IPB sudah diakui secara nasional maupun internasional. Secara nasional memperoleh Akreditasi A dari Badan Akreditasi Nasional-Perguruan Tinggi (BAN-PT) (No. 8616/SK/BAN-PT/Ak-PNB/M/I/2021). Sementara itu, secara internasional memperoleh pengakuan dari Institute of Food Technologist (IFT) sejak tahun 2010, Internasional Union of Food Science and Technology (IUFoST) sejak tahun 2013, dan Accreditation in Engineering Computer Sciencies Natural Sciences Mathematics (ASIIN) dan European Quality Assurance System (EQAS) sejak tahun 2023.'
    },
    {
        id: 7,
        question: 'Bagaimana kurikulum yang digunakan pada Program Studi Teknologi Pangan IPB?',
        answer: 'Program Studi Teknologi Pangan IPB University dirancang untuk menghasilkan lulusan yang mampu bekerja secara efektif dan profesional di sektor pangan, baik di industri pangan, lembaga pemerintah maupun sebagai wirausahawan. Sejak tahun 2001, Program Sarjana Teknologi Pangan telah mengadopsi kurikulum ilmu pangan yang direkomendasikan oleh Institute of Food Technologists (IFT), meliputi kimia pangan dan analisis, keamanan pangan dan mikrobiologi, pengolahan dan rekayasa pangan, kualitas gizi, ilmu pangan terapan dan keterampilan sukses (kemampuan profesional, berpikir kritis dan keterampilan memecahkan masalah, keterampilan interaksi, keterampilan organisasi dan komunikasi).'
    },
    {
        id: 8,
        question: 'Apa perbedaan jurusan teknologi pangan dan teknik pangan?',
        answer: 'Persamaan antara keduanya hanya terletak pada prinsip aplikasi materinya yaitu mikrobiologi, aplikasi sains fisik, kimia, dan biokimia. Selain itu, keduanya juga memiliki fokus yang sama dalam aspek kualitas, keamanan, dan kenyamanan dalam menciptakan pangan berkualitas. Jika ditelusuri lebih jauh lagi, yang membedakan antara kedua jurusan ini yaitu pada teknik pangan lebih condong pada pemanfaatan prinsip rekayasa yang lebih menjurus pada aplikasi fisika, seperti keseimbangan massa dan energi, termodinamika, aliran fluida dan kalor, serta perpindahan massa. Sedangkan Teknologi Pangan mempelajari penerapan ilmu pangan untuk pemilihan, pengawetan, pengolahan, pengemasan, distribusi, dan penggunaan pangan yang aman, bergizi, dan sehat. Di dalam teknologi pangan, kita akan mempelajari mengenai teknik pengolahan pangan mencakup produksi, proses, dan distribusi hingga memastikan produk aman untuk dikonsumsi.'
    },
    {
        id: 9,
        question: 'Apakah untuk masuk ITP harus ahli dalam mata pelajaran Kimia?',
        answer: 'ITP fokus terhadap mata kuliah yang science-based sehingga Mahasiswa/i ITP harus bisa menguasai di mata kuliah sains, seperti Kimia, Biologi, Matematika, dan Fisika.'
    },
    {
        id: 10,
        question: 'Apakah terdapat kelas internasional pada Departemen Ilmu dan Teknologi Pangan?',
        answer: 'Mulai pada tahun akademik 2018/2019, Departemen ITP menyediakan kelas internasional untuk Mahasiswa/i asing dan Indonesia.'
    }
]

const FAQPage = () => {
    return (
        <main>
            <Banner title="FAQ'S" link='/registration'/>
            <div className='space-y-12'>
                <section className="lg:container mx-auto">
                    <div className="grid justify-items-center pb-4 my-4">
                        <div className="rounded-full px-3 py-4 w-10/12 text-md leading-6 font-semibold text-stone-900 bg-[#FDCF50] ">
                            FAQ LCTIP XXXII
                        </div>
                    </div>
                    <div className='w-10/12 mx-auto my-12'>
                        <Accordion collapseAll>
                            {
                                questionCompetition.map((i, key) => {
                                    return (
                                        <Accordion.Panel key={key}>
                                            <Accordion.Title className="text-stone-900 hover:text-[#79245B] flex items-center text-sm lg:text-base justify-between w-full p-5 text-left border-0 border-b-0 border-stone-200">
                                                <div>{i.question}</div>
                                            </Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-justify text-[0.6rem] lg:text-sm leading-5 lg:leading-7 text-stone-900">
                                                    {i.answer}
                                                </p>
                                            </Accordion.Content>
                                        </Accordion.Panel>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                    <div className="grid justify-items-center pb-4 my-4">
                        <div className="rounded-full px-3 py-4 w-10/12 text-md leading-6 font-semibold text-stone-900 bg-[#FDCF50] ">
                            FAQ FST IPB University
                        </div>
                    </div>
                    <div className='w-10/12 mx-auto my-12'>
                        <Accordion collapseAll>
                            {
                                CampusCompetition.map((i, key) => {
                                    return (
                                        <Accordion.Panel key={key}>
                                            <Accordion.Title className="text-stone-900 hover:text-[#79245B] flex items-center text-sm lg:text-base justify-between w-full p-5 text-left border-0 border-b-0 border-stone-200">
                                                <div>{i.question}</div>
                                            </Accordion.Title>
                                            <Accordion.Content>
                                                <p className="mb-2 text-justify text-[0.6rem] lg:text-sm leading-5 lg:leading-7 text-stone-900">
                                                    {i.answer}
                                                </p>
                                            </Accordion.Content>
                                        </Accordion.Panel>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </section>
            </div>

            {/* Organizer */}
            <section className='w-full h-full flex flex-col items-center'>
                <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="Behind the Greatest Event" isWhite={true} isLeft={true} content={Organizer} description={<>Acara ini diselenggarakan oleh Himpunan Mahasiswa Ilmu dan Teknologi Pangan (HIMITEPA) dari Departemen Ilmu dan Teknologi Pangan IPB University.<br />Sebagai pionir dalam memecahkan masalah terkait pangan yang terbagi ke dalam empat divisi, yaitu Kimia Pangan, Mikrobiologi Pangan, Biokimia Pangan, dan Rekayasa Proses Pangan.</>} />
            </section>

            {/* Sponsors */}
            <section className='w-full h-full flex flex-col items-center  gap-12'>
                <SectionTitle category="Partner in Progress" title="Sponsorship" isWhite={true} />
                <SponsorsCard />
            </section>
        </main>
    )
}

export default FAQPage;