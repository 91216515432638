import React from 'react';

import Banner from '../components/Banner';
import InformationCard from '../components/InformationCard';
import SectionTitle from '../components/SectionTitle';
import SponsorsCard from '../components/SponsorsCard';

import Organizer from '../assets/organizer.png';
import QR from '../assets/mystery-guideline.png';

const ChallengePage = () => {
    return (
        <main>
            <Banner title="MYSTERY QUESTSCAPE" link='/registration'/>
            
            <div className='space-y-12'>
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Apa Itu Mystery Questscape" category="Challenge Guideline" isWhite={true} isLeft={true} content={QR} description={<>Mystery Questscape merupakan permainan berupa tebak kata berbasis emoji yang terdiri atas 3 kategori pertanyaan mengenai nasionalisme. <br /><br />Permainan dilaksanakan pada kegiatan Edu - Camp Tour yang terdiri dari 2 sesi, yaitu sebelum peserta memasuki tempat tujuan pertama dan kedua. <br /><br />Permainan ini akan dilakukan berkelompok sesuai dengan tim yang berlomba. Teknis dilakukan dengan diberikan pertanyaan sejumlah 12 soal dan jawaban dikumpulkan melalui Google Formulir. Tim dengan poin penilaian tertinggi akan mendapatkan reward. Dengan demikian, semua peserta akan terlibat dalam tantangan yang menyenangkan dan menarik ini!</>} />
                </section>

                {/* Organizer */}
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="Behind the Greatest Event" isWhite={true} isLeft={true} content={Organizer} description={<>Acara ini diselenggarakan oleh Himpunan Mahasiswa Ilmu dan Teknologi Pangan (HIMITEPA) dari Departemen Ilmu dan Teknologi Pangan IPB University.<br />Sebagai pionir dalam memecahkan masalah terkait pangan yang terbagi ke dalam empat divisi, yaitu Kimia Pangan, Mikrobiologi Pangan, Biokimia Pangan, dan Rekayasa Proses Pangan.</>} />
                </section>

                {/* Sponsors */}
                <section className='w-full h-full flex flex-col items-center  gap-12'>
                    <SectionTitle category="Partner in Progress" title="Sponsorship" isWhite={true} />
                    <SponsorsCard />
                </section>
            </div>
        </main>
    )
}

export default ChallengePage;