import React from "react";

import { useNavigate } from "react-router-dom";

import Banner from "../assets/lctip-banner.png";
import BannerSmall from "../assets/lctip-banner-small.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Hero = (props) => {

    const navigate = useNavigate();

    return (
        <div className="w-full h-full flex flex-col gap-12 my-[100px] md:my-[200px]">
            <section className='w-full h-fit md:h-[60vh] flex flex-col md:relative bg-white'>
                <div className='w-[80%] h-full mx-auto'>
                    <div className='w-full flex flex-col gap-4 md:gap-0 md:flex-row items-center justify-between'>
                        <h1 className='text-5xl text-left leading-tight font-semibold text-stone-900'>{props.title}</h1>
                        <div className='flex flex-col gap-6'>
                            <p className='text-[16px] text-left font-normal text-stone-900 text-balance'>{props.description}</p>
                            <button onClick={() => navigate(props.navigation)} className='w-fit h-auto flex items-center justify-center gap-2 border border-stone-900 bg-white text-stone-900 rounded-full pt-[8px] pb-[10px] px-[20px] group'>
                                <p className='font-semibold transform ease-in-out duration-300 transition-all group-hover:mr-8 '>{props.button}</p>
                                <FontAwesomeIcon icon={faArrowRight} className='w-[12px] h-[12px] text-[var(--color-primary)]' />
                            </button>
                        </div>
                    </div>
                </div>
                <picture class="flex justify-center items-center md:absolute bottom-0 m-auto left-0 right-0 w-[70%] h-auto">
                    <source srcset={Banner} media="(min-width: 768px)" />
                    <img src={BannerSmall} alt="" class="w-full h-auto" />
                </picture>
            </section>
        </div>
    );
};

export default Hero;
