import React from 'react';

import SectionTitle from '../components/SectionTitle';
import EventCard from '../components/EventCard';

import { faTrophy, faBook, faBomb } from '@fortawesome/free-solid-svg-icons';

const EventPage = () => {
    return (
        <main>
            <section className='w-full h-full flex flex-col items-center gap-12 my-36' >
                <SectionTitle category="Category" title="Events List" isWhite={true} />
                <div className="w-full flex flex-col items-center gap-12">
                    <EventCard icon={faTrophy} title="Competition" content="Kompetisi LCTIP XXXII merupakan lomba cepat tepat terkait ilmu pangan yang terdiri atas beberapa babak ditujukan spesial bagi siswa dan siswi SMA/sederajat yang memiliki ketertarikan dalam bidang pangan." link="/events/competition" />
                    <EventCard icon={faBook} title="National Seminar" content="Seminar skala nasional yang akan memberikan pengetahuan baru, memperkenalkan inovasi terbaru, atau mendiskusikan solusi untuk masalah-masalah yang ada dalam bidang pangan. Tahun ini, Seminar Nasional LCTIP XXXII mengangkat tema Symphony of Creamy Bliss as A Trend in Food Industry." link="/events/seminar" />
                    <EventCard icon={faBomb} title="Mystery Questscape" content="Mystery Questscape merupakan permainan berupa tebak kata berbasis emoji yang terdiri atas 3 kategori pertanyaan mengenai nasionalisme. Permainan dilaksanakan pada kegiatan Edu - Camp Tour yang terdiri dari 2 sesi, yaitu sebelum peserta memasuki tempat tujuan pertama dan kedua. Permainan ini akan dilakukan berkelompok sesuai dengan tim yang berlomba. Dengan demikian, semua peserta akan terlibat dalam tantangan yang menyenangkan dan menarik ini!" link="/events/challenge" />
                </div>
            </section >
        </main>
    )
}

export default EventPage;