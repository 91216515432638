import React from 'react';

import Hero from '../components/Hero';

const MaintenancePage = () => {
    return (
        <main>
            <Hero title="See You Next Year" description="Saat ini Lomba Cepat Tepat Ilmu Pangan telah selesai. Sampai berjumpa tahun depan! 👋🏻" button="Back" navigation="/" />
        </main>
    )
}

export default MaintenancePage;