import React from 'react'

import Hero from '../components/Hero'
import PrizeCard from '../components/PrizeCard';
import SectionTitle from '../components/SectionTitle';
import EventCard from '../components/EventCard';
import TimelineCard from '../components/TimelineCard';
import InformationCard from '../components/InformationCard';
import SponsorsCard from '../components/SponsorsCard';
import CarouselContent from '../components/CarouselContent';

import Pics from "../assets/logo.png";
import Organizer from "../assets/organizer.png";
import { faBomb, faBook, faTrophy } from '@fortawesome/free-solid-svg-icons';

import image1 from "../assets/images/main/1.JPG";
import image2 from "../assets/images/main/2.JPG";
import image3 from "../assets/images/main/3.JPG";
import image4 from "../assets/images/main/4.JPG";
import image5 from "../assets/images/main/5.JPG";
import image6 from "../assets/images/main/6.JPG";
import image7 from "../assets/images/main/7.JPG";
import image8 from "../assets/images/main/8.JPG";
import image9 from "../assets/images/main/9.JPG";
import image10 from "../assets/images/main/10.JPG";
import image11 from "../assets/images/main/11.JPG";
import image12 from "../assets/images/main/12.JPG";
import image13 from "../assets/images/main/13.JPG";
import image14 from "../assets/images/main/14.JPG";
import image15 from "../assets/images/main/15.JPG";
import image16 from "../assets/images/main/16.JPG";

const imageCarousel = [
    {id: 1, name: "Image1", imageUrl: image1},
    {id: 2, name: "Image2", imageUrl: image2},
    {id: 3, name: "Image3", imageUrl: image3},
    {id: 4, name: "Image4", imageUrl: image4},
    {id: 5, name: "Image5", imageUrl: image5},
    {id: 6, name: "Image6", imageUrl: image6},
    {id: 7, name: "Image7", imageUrl: image7},
    {id: 8, name: "Image8", imageUrl: image8},
    {id: 9, name: "Image9", imageUrl: image9},
    {id: 10, name: "Image10", imageUrl: image10},
    {id: 11, name: "Image11", imageUrl: image11},
    {id: 12, name: "Image12", imageUrl: image12},
    {id: 13, name: "Image13", imageUrl: image13},
    {id: 14, name: "Image14", imageUrl: image14},
    {id: 15, name: "Image15", imageUrl: image15},
    {id: 16, name: "Image16", imageUrl: image16}
]

const MainPage = () => {
    return (
        <main className='relative overflow-hidden'>
            <Hero title="Lomba Cepat Tepat Ilmu Pangan XXXII" description="The Biggest Annual Food Science and Technology Competition for High School Students from All Over Indonesia held by Himpunan Mahasiswa Ilmu dan Teknologi Pangan IPB" button="Registration" navigation="/registration" />
            <div className='space-y-12'>
                {/* Carousel */}
                <section className='w-full h-full flex flex-col items-center gap-12'>
                    <SectionTitle category="Recap LCTIP XXXII" title="Memories of LCTIP XXXII" isWhite={true} />
                    <div className="w-10/12 flex items-center">
                        <CarouselContent imageCarousel={imageCarousel}/>
                    </div>
                </section>

                {/* About */}
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="About the Events" isWhite={true} isLeft={true} content={Pics} description={<>The biggest annual food science and technology competition for high school students all over Indonesia held by Himpunan Mahasiswa Ilmu dan Teknologi Pangan IPB with<span className="font-bold"> remarkable series of events</span>. <br></br><br></br>This year, we're returning with an <span className="font-bold">intriguing new topic</span> that will gave you more fun and excitement through offline experience!</>} />
                </section>

                {/* Category */}
                <section className='w-full h-full flex flex-col items-center gap-12'>
                    <SectionTitle category="Category" title="Events List" isWhite={true} />
                    <div className="w-full flex flex-col items-center gap-12">
                        <EventCard icon={faTrophy} title="Competition" content="Kompetisi LCTIP XXXII merupakan lomba cepat tepat terkait ilmu pangan yang terdiri atas beberapa babak ditujukan spesial bagi siswa dan siswi SMA/sederajat yang memiliki ketertarikan dalam bidang pangan." link="/events/competition" />
                        <EventCard icon={faBook} title="National Seminar" content="Seminar skala nasional yang akan memberikan pengetahuan baru, memperkenalkan inovasi terbaru, atau mendiskusikan solusi untuk masalah-masalah yang ada dalam bidang pangan. Tahun ini, Seminar Nasional LCTIP XXXII mengangkat tema Symphony of Creamy Bliss as A Trend in Food Industry." link="/events/seminar" />
                        <EventCard icon={faBomb} title="Mystery Questscape" content="Mystery Questscape merupakan permainan berupa tebak kata berbasis emoji yang terdiri atas 3 kategori pertanyaan mengenai nasionalisme. Permainan dilaksanakan pada kegiatan Edu - Camp Tour yang terdiri dari 2 sesi, yaitu sebelum peserta memasuki tempat tujuan pertama dan kedua. Permainan ini akan dilakukan berkelompok sesuai dengan tim yang berlomba. Dengan demikian, semua peserta akan terlibat dalam tantangan yang menyenangkan dan menarik ini!" link="/events/challenge" />
                    </div>
                </section>

                {/* Timeline */}
                <section className="w-full bg-[#695497] py-20 gap-12">
                    <SectionTitle category="Reminder" title="Events Timeline" isWhite={false} />
                    <div className="w-9/12 flex flex-col mx-auto gap-4">
                        <TimelineCard event="Registrasi 1" date="1 - 31 Agustus 2024" />
                        <TimelineCard event="Day 1 (Qualification Stage, Edu & Campus Tour)" date="1 November 2024" />
                        <TimelineCard event="Registrasi 2" date="1 November 2024 ( 18.00 - 23.59 )" />
                        <TimelineCard event="Day 2 (Quarter Final Stage, Seminar Nasional)" date="2 November 2024" />
                        <TimelineCard event="Day 3 (Semi Final Stage, Mix and Make, and Final Stage)" date="3 November 2024" />
                    </div>
                </section>

                {/* Prizes */}
                <section className='w-full h-full flex flex-col items-center  gap-12'>
                    <SectionTitle category="What Can You Get?" title="Prizes Competition" isWhite={true} />
                    <div className="w-10/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        <PrizeCard title="Juara 1" money="Rp 6.000.000" prize="+ Piala + Golden Ticket ITP IPB + Piala bergilir + Sertifikat" />
                        <PrizeCard title="Juara 2" money="Rp 4.000.000" prize="+ Piala + Sertifikat" />
                        <PrizeCard title="Juara 3" money="Rp 2.000.000" prize="+ Piala + Sertifikat" />
                        <PrizeCard title="Juara Harapan" money="Rp 600.000" prize="+ Sertifikat" />
                    </div>
                </section>

                {/* Organizer */}
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="Behind the Greatest Event" isWhite={true} isLeft={true} content={Organizer} description={<>Acara ini diselenggarakan oleh Himpunan Mahasiswa Ilmu dan Teknologi Pangan (HIMITEPA) dari Departemen Ilmu dan Teknologi Pangan IPB University.<br />Sebagai pionir dalam memecahkan masalah terkait pangan yang terbagi ke dalam empat divisi, yaitu Kimia Pangan, Mikrobiologi Pangan, Biokimia Pangan, dan Rekayasa Proses Pangan.</>} />
                </section>

                {/* Sponsors */}
                <section className='w-full h-full flex flex-col items-center  gap-12'>
                    <SectionTitle category="Partner in Progress" title="Sponsorship" isWhite={true} />
                    <SponsorsCard />
                </section>
            </div>
        </main>
    )
}

export default MainPage;