import React from 'react';

import SectionTitle from './SectionTitle';

const InformationCard = (props) => {
    return (
        <div className='w-10/12 grid grid-row lg:grid-cols-2 py-12 gap-6 md:gap-12'>
            <div className="order-1 justify-items-center md:justify-items-start space-y-6">
                <SectionTitle category={props.category} title={props.title} isWhite={props.isWhite} isLeft={props.isLeft} />
                <p className="text-[0.6rem] md:text-base text-justify lg:text-left leading-5 md:leading-8 text-stone-800">
                    {props.description}
                </p>
            </div>

            <div className="order-2 grid justify-items-center lg:justify-items-center content-center">
                <img src={props.content} className=" overflow-hidden rounded-lg w-9/12 hover:scale-110 ease-in-out duration-300" alt="..."></img>
            </div>
        </div>
    )
}

export default InformationCard;