import React from "react";

import { Carousel } from "flowbite-react";

const CarouselContent = ({imageCarousel}) => {
    return (
        <div className="w-full h-[24vh] lg:h-[80vh] rounded-xl md:rounded-lg overflow-hidden no-drag" draggable="false" onDragStart={(e) => { e.preventDefault() }}>
            <Carousel indicators={false} slideInterval={3000}>
                {
                    imageCarousel.map((i, key) => {
                        return (
                            <img
                                alt={i.name}
                                className="pointer-events-none"
                                src={i.imageUrl}
                            />
                        )
                    })
                }
            </Carousel>
        </div>
    )
};

export default CarouselContent;
