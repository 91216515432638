import React from 'react';

import Banner from '../components/Banner';
import InformationCard from '../components/InformationCard';
import SectionTitle from '../components/SectionTitle';
import TimelineCard from '../components/TimelineCard';
import PrizeCard from '../components/PrizeCard';
import SponsorsCard from '../components/SponsorsCard';

import Flyer from '../assets/flyer.jpg';
import QR from "../assets/guidebook.png";
import Organizer from "../assets/organizer.png";

const CompetitionPage = () => {
    return (
        <main className='relative overflow-hidden'>
            <Banner title="COMPETITION" link='/registration'/>

            <div className='space-y-12'>
                <div className="rounded-2xl w-10/12 h-full mx-auto">
                    <img src={Flyer} alt="..." className="lg:w-full rounded-2xl" />
                </div>
                {/* About */}
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="About the Events" isWhite={true} isLeft={true} content={QR} description={<>Kompetisi LCTIP XXXI merupakan lomba cepat tepat terkait ilmu pangan yang terdiri atas beberapa babak ditujukan spesial bagi siswa dan siswi SMA/Sederajat yang memiliki <span className="font-bold">ketertarikan dalam bidang pangan</span>.
                        <br /><br />Beberapa mata pelajaran yang diujikan dalam lomba ini adalah Matematika, Fisika, Kimia, Biologi, dan Ilmu Pangan. Tujuan diadakannya lomba ini adalah <span className="font-bold">meningkatkan pengetahuan, wawasan, dan kepedulian siswa SMA terhadap permasalahan pangan di Indonesia</span>.</>} />
                </section>

                {/* Timeline */}
                <section className="w-full bg-[#695497] py-20 gap-12">
                    <SectionTitle category="Reminder" title="Events Timeline" isWhite={false} />
                    <div className="w-9/12 flex flex-col mx-auto gap-4">
                        <TimelineCard event="Registrasi 1" date="1 - 31 Agustus 2024" />
                        <TimelineCard event="Day 1 (Qualification Stage, Edu & Campus Tour)" date="1 November 2024" />
                        <TimelineCard event="Registrasi 2" date="1 November 2024 ( 18.00 - 23.59 )" />
                        <TimelineCard event="Day 2 (Quarter Final Stage, Seminar Nasional)" date="2 November 2024" />
                        <TimelineCard event="Day 3 (Semi Final Stage, Mix and Make, and Final Stage)" date="3 November 2024" />
                    </div>
                </section>

                {/* Prizes */}
                <section className='w-full h-full flex flex-col items-center  gap-12'>
                    <SectionTitle category="What Can You Get?" title="Prizes Competition" isWhite={true} />
                    <div className="w-10/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        <PrizeCard title="Juara 1" money="Rp 6.000.000" prize="+ Piala + Golden Ticket ITP IPB + Piala bergilir + Sertifikat" />
                        <PrizeCard title="Juara 2" money="Rp 4.000.000" prize="+ Piala + Sertifikat" />
                        <PrizeCard title="Juara 3" money="Rp 2.000.000" prize="+ Piala + Sertifikat" />
                        <PrizeCard title="Juara Harapan" money="Rp 600.000" prize="+ Sertifikat" />
                    </div>
                </section>

                {/* Organizer */}
                <section className='w-full h-full flex flex-col items-center'>
                    <InformationCard title="Lomba Cepat Tepat Ilmu Pangan XXXII" category="Behind the Greatest Event" isWhite={true} isLeft={true} content={Organizer} description={<>Acara ini diselenggarakan oleh Himpunan Mahasiswa Ilmu dan Teknologi Pangan (HIMITEPA) dari Departemen Ilmu dan Teknologi Pangan IPB University.<br />Sebagai pionir dalam memecahkan masalah terkait pangan yang terbagi ke dalam empat divisi, yaitu Kimia Pangan, Mikrobiologi Pangan, Biokimia Pangan, dan Rekayasa Proses Pangan.</>} />
                </section>

                {/* Sponsors */}
                <section className='w-full h-full flex flex-col items-center  gap-12'>
                    <SectionTitle category="Partner in Progress" title="Sponsorship" isWhite={true} />
                    <SponsorsCard />
                </section>
            </div>
        </main>
    )
}

export default CompetitionPage;