import React from 'react';

const TimelineCard = (props) => {
    return (
        <section className="flex items-center justify-between">
            <div className="flex mr-3 md:mr-4">
                <p className="text-[0.6rem] md:text-base text-left text-white">{props.event}</p>
            </div>
            <div className="flex flex-auto mx-auto">
                <p className="border-b border-dotted w-full"></p>
            </div>
            <div className="ml-2 md:ml-4">
                <p className="text-[0.6rem] md:text-base text-right text-white">{props.date}</p>
            </div>
        </section>
    );
}

export default TimelineCard;