import React from "react";

import Sponsor1 from '../assets/sponsors/1.png';
import Sponsor2 from '../assets/sponsors/2.png';
import Sponsor3 from '../assets/sponsors/3.jpg';
import Sponsor4 from '../assets/sponsors/4.jpg';
import Sponsor5 from '../assets/sponsors/5.png';
import Sponsor6 from '../assets/sponsors/6.png';
import Sponsor7 from '../assets/sponsors/7.png';
import Sponsor8 from '../assets/sponsors/8.png';
import Sponsor9 from '../assets/sponsors/9.png';
import Sponsor10 from '../assets/sponsors/10.png';

const SponsorsCard = () => {
    const sponsors = [
        { id: 1, name: 'Sponsor 1', imageUrl: Sponsor1 },
        { id: 2, name: 'Sponsor 2', imageUrl: Sponsor2 },
        { id: 3, name: 'Sponsor 3', imageUrl: Sponsor3 },
        { id: 4, name: 'Sponsor 4', imageUrl: Sponsor4 },
        { id: 5, name: 'Sponsor 5', imageUrl: Sponsor5 },
        { id: 6, name: 'Sponsor 6', imageUrl: Sponsor6 },
        { id: 7, name: 'Sponsor 7', imageUrl: Sponsor7 },
        { id: 8, name: 'Sponsor 8', imageUrl: Sponsor8 },
        { id: 9, name: 'Sponsor 9', imageUrl: Sponsor9 },
        { id: 10, name: 'Sponsor 10', imageUrl: Sponsor10}
    ];

    return (
        <section className="flex flex-row flex-wrap items-center justify-center gap-6 w-8/12">
            {sponsors.map((sponsor) => (
                <div
                    key={sponsor.id}
                    className="flex flex-col items-center justify-center p-4"
                >
                    <img
                        src={sponsor.imageUrl}
                        alt={sponsor.name}
                        className="w-32 h-32 object-contain"
                    />
                </div>
            ))}
        </section>
    )
}

export default SponsorsCard;